import { useCallback } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useI18n } from '@src/locales';
import * as Styles from './locales.styled';

const languages = process.env.NEXT_PUBLIC_LOCALES.split(',');

const Locales = ({ urls, topUrlType }) => {
    const { locale, query } = useRouter();
    const { t } = useI18n();

    const Url = useCallback(({ language, url }) => {
        const className = locale === language ? '__active' : null;
        const lang = language.replace('be', '');

        if (topUrlType === 'a') {
            return <a href={url} className={className}>{lang.toUpperCase()}</a>;
        }

        return <Link href={url} passHref prefetch={false} locale={language}><a className={className}>{lang.toUpperCase()}</a></Link>;
    }, [locale, topUrlType]);

    return (
        <aside className="d-inline-flex flex-column items-center">
            <Styles.Header>
                {languages.map((language) => (urls?.[language] ? <Url key={language} language={language} url={urls[language] + ('key' in query ? `?key=${query.key}&lang=${query.lang || locale}` : '')} /> : null))}
            </Styles.Header>
            <Styles.Label>{t('lang')}</Styles.Label>
        </aside>
    );
};

export default Locales;
