import styled from 'styled-components';

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;

    > a {
        border: 2px solid #333;
        border-radius: 4px;
        color: #333;
        font-size: .8em;
        padding: .4em .6em;
        transition: all .2s ease-out;

        @media (max-width: 340px) {
            padding: .3em .5em;
        }

        &.__active {
            color: var(--primary);
            border-color: var(--primary);
        }

        &:hover {
            color: var(--primary);
            border-color: var(--primary);
        }
    }
`;

export const Label = styled.span`
    display: block;
    font-size: .8em;
    text-align: center;
    padding-top: .25em;
`;
